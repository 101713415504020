import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMUserData from "../../../utilities/TZMUserData";
import { UfService } from "../servicos/UfService";
import { EditarUf } from "./EditarUf";
import { numberFormat } from "../../../utilities/TZMFormat";


const title = "Limites por UF";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Uf extends Component {

	state = {
		codigo: "",
		nome: "",
		sigla: "",
		limiteFretePeso: "",
		limiteFreteValor: ""
	};

	ufService = new UfService();

	handleChange = event => {
		this.setState({[event.name]: event.value});
	}

	listarUfs = () => {
		let query = [];
		if (this.state.codigo?.length) {
			query.push(`codigo=ik='${this.state.codigo}'`);
		}
		if (this.state.nome?.length) {
			query.push(`nome=ik='${this.state.nome}'`);
		}
		if (this.state.sigla?.length) {
			query.push(`sigla=ik='${this.state.sigla}'`);
		}
		if (query.length > 0) {
			this.ufService.listarTela(`?search=${query.join(";")}&size=100`).then(Ufs => this.setState({Ufs}));
		} else {
			this.ufService.listarTela("?size=100").then(Ufs => this.setState({Ufs}));
		}
		
	}

	editarUf = uf => {
		ModalRealm.showDialog(<EditarUf uf={uf} onModalClose={this.listarUfs} />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMTextField sortable header="Codigo" label="Codigo" col={2} name="codigo" value={this.state.codigo} onChange={this.handleChange}/>
									<TZMTextField label="Nome" col={4} name="nome" value={this.state.nome} onChange={this.handleChange}/>
									<TZMTextField label="Sigla" col={2} name="sigla" value={this.state.sigla} onChange={this.handleChange}/>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarUfs}/>
							</TZMPanelFooter>
						</TZMPanel>
					</div>
				</div>
				<div style={{height: "8px"}} />
				<TZMPanel header="Resultado da Pesquisa">
					<TZMTable value={this.state.Ufs} paginator rows={30}>

						<TZMColumn style={{width: "12em"}} header="Código" field="codigo" sortable/>
						<TZMColumn header="Nome" field="nome" sortable/>
						<TZMColumn style={{width: "12em"}} header="Sigla" field="sigla"/>
						<TZMColumn style={{width: "10em", textAlign: "right"}} header="Limite Frete Peso" body={m => `${numberFormat.format(m.limiteFretePeso)} %`}/>
						<TZMColumn style={{width: "10em", textAlign: "right"}} header="Limite Frete Valor" body={m => `${numberFormat.format(m.limiteFreteValor)} %`}/>

						
						<TZMColumn header="Ações" style={{width: "6em", textAlign: "center"}} body={v => (
							<div>
								{TZMUserData.hasRole("FRETE_LIMITE_UF_E") ? <span title="Editar Uf" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarUf(v)} /> : null}
							</div>
						)}/>
					</TZMTable>
				</TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
