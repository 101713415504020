import React, {Component} from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {Column} from "primereact/components/column/Column";
import {UfService} from "../servicos/UfService";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import {TransportadoraUtils} from "../transportadora/TransportadoraUtils";
import moment from "moment";
import TZMRadioGroup from "../../../components/common/TZMRadioGroup";
import TZMRadioButton from "../../../components/common/TZMRadioButton";
import TZMTextField from "../../../components/common/TZMTextField";
import {Validator} from "../../../utilities/TZMValidator";
import TZMPopup from "../../../components/dialog/TZMPopup";
import {httpGet} from "../../../utilities/TZMRequest";
import {toDecimalFixed} from "../../../components/common/TZMFormatter";
import {toNumber} from "lodash";
import {TransportadoraService} from "../servicos/TransportadoraService";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";

const title = "Indicadores - Frete Peso / Frete Valor";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Indicadores extends Component {

	constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        moment.locale('pt-BR');
        this.state = {
			Indicadores:[],
			params: {
				numeroFatura: null,
				numeroCte: null,
				transportadoraIndicador: null,
				dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
				dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
				dataEmissao_fim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
				dataEmissao_inicio: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
				filtroRadioGroup: 'NF'
			}
		};
    }

	export() {
    	this.dt.exportCSV();
    }

	ufService = new UfService();
	transportadoraService = new TransportadoraService();

	handleChange = event => {
		this.setState({[event.name]: event.value});
	}

	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});		
	}

	listarUfs = () => {
		let query = [];
		if (this.state.codigo?.length) {
			query.push(`codigo=ik='${this.state.codigo}'`);
		}
		if (this.state.nome?.length) {
			query.push(`nome=ik='${this.state.nome}'`);
		}
		if (this.state.sigla?.length) {
			query.push(`sigla=ik='${this.state.sigla}'`);
		}
		if (query.length > 0) {
			this.ufService.listarTela(`?search=${query.join(";")}&size=100`).then(Ufs => this.setState({Ufs}));
		} else {
			this.ufService.listarTela("?size=100").then(Ufs => this.setState({Ufs}));
		}
		
	}

	listarFreteIndicadoresBase = () => {
		let messages = [];

		const validacaoDatafim = Validator.isDate(this.state.params?.dataEmissao_fim);
		const validacaoDataIncio = Validator.isDate(this.state.params?.dataEmissao_inicio);
		const periodoInvalido = this.state.params?.dataEmissao_fim < this.state.params?.dataEmissao_inicio;
		if(!validacaoDataIncio && !validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador && !this.state.params?.numeroCte){
			messages.push('Você deve informar pelo menos um dos campos (Período, Transportadora, Nº do CT-e ou Nº da Fatura)')
		}else{
			if(!validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data final inválida ou não informada.') 
			if(!validacaoDataIncio && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data inicial inválida ou não informada.') 
			if(periodoInvalido && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Período informado inválido, a data final deve ser maior ou igual que a inicial.' )
		}		

		if(messages !== null && messages.length > 0){
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages}></TZMPopup>);
		}else{
			let params = [];
			if (this.state.params?.filtroRadioGroup) params.push(`filtroRadioGroup=${this.state.params?.filtroRadioGroup}`);
			if (this.state.params?.dataEmissao_inicio) params.push(`dataEmissao_inicio=${this.state.params?.dataEmissao_inicio}`);
			if (this.state.params?.dataEmissao_fim) params.push(`dataEmissao_fim=${this.state.params?.dataEmissao_fim}`);
			if (this.state.params?.transportadoraIndicador) params.push(`transportadora=${this.state.params?.transportadoraIndicador.id}`);
			if (this.state.params?.numeroFaturaIndicador) params.push(`numeroFatura=${this.state.params?.numeroFaturaIndicador}`);
			if (this.state.params?.numeroCte) params.push(`numeroCte=${this.state.params?.numeroCte}`);
			
			httpGet(`/vw/frete-indicadores-peso-valor?${params.join("&")}`).then(resultado => {
				let Indicadores = [];
				for (let i = 0; i < resultado.length; i++) {
					let item = resultado[i];
					item.dataEmissaoCte = moment(item.dataEmissaoCte).format("DD/MM/YYYY");
					item.dataEmissaoNf = moment(item.dataEmissaoNf).format("DD/MM/YYYY");
					item.valorCte = toDecimalFixed(item.valorCte, 2);
					item.pesoCte = toDecimalFixed(item.pesoCte, 2);
					item.notaFiscalValor = toDecimalFixed(item.notaFiscalValor, 2);
					item.notaFiscalPesoBruto = toDecimalFixed(item.notaFiscalPesoBruto, 2);
					item.nfFretePagar = toDecimalFixed(item.nfFretePagar, 2);
					item.indicadorFretePeso = toDecimalFixed(item.indicadorFretePeso, 2);
					item.indicadorFreteValor = toDecimalFixed(item.indicadorFreteValor, 2);
					Indicadores.push(item);
				}
				this.setState({Indicadores})
			});
		}
	}

	handleChangeRadioButton = (event) => {
		let params = this.state.params;
		params.filtroRadioGroup = event.value;
		this.setState({params});
	}

	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

	autoCompleteTransportadoraIndicador = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({ transportadorasIndicador: response });
		});
	}

	exportCSV = () => {
		Indicadores.current.exportCSV();
    };

    exportPdf = () => {
        /*import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('products.pdf');
            });
        });*/
    };

    exportExcel = () => {
        /*import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(Indicadores);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            this.saveAsExcelFile(excelBuffer, 'Indicadores');
        });*/
    };

    saveAsExcelFile = (buffer, fileName) => {
        /*import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });*/
    };

	onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
	}
	
	onHide(name) {
        this.setState({
            [`${name}`]: false
        });
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
							<div className="ui-g">
										<TZMCalendar
											col={1}
											label="Período"
											placeholder="Inicial"
											name="dataEmissao_inicio"
											onChange={this.handleUpdate}
											value={this.state.params.dataEmissao_inicio}
										/>
										<TZMCalendar
											col={1}
											label="&nbsp;"
											placeholder="Final"
											name="dataEmissao_fim"
											onChange={this.handleUpdate}
											value={this.state.params.dataEmissao_fim}/>
										<TZMRadioGroup label="Referência (Período)" col={3}>
											<TZMRadioButton
												name="radioButton"
												label="Data de Emissão da NF"
												value="NF"
												onChange={e => this.handleChangeRadioButton(e)}
												checked={this.state.params.filtroRadioGroup === "NF"}
												id="rb1"/>
											<TZMRadioButton
												name="radioButton"
												label="Data de Emissão do CT-e"
												value="CTE"
												onChange={e => this.handleChangeRadioButton(e)}
												checked={this.state.params.filtroRadioGroup === "CTE"}
												id="rb2"/>
										</TZMRadioGroup>

										<div className="ui-g-4">
											<TZMAutoComplete 
												itemTemplate={TransportadoraUtils.transportadoraTemplate} 
												onClear={this.handleClear}  
												onSelect={this.queryAutoComplete} 
												onChange={this.queryAutoComplete}																
												placeholder="Razão social, fantasia ou CNPJ" 
												suggestions={this.state.transportadorasIndicador} 
												completeMethod={this.autoCompleteTransportadoraIndicador} 
												name="transportadoraIndicador"
												label="Transportadora" 
												value={this.state.params.transportadoraIndicador} 
												field="razaoSocial" 
											/>
										</div>
										<div className="ui-g-1">
											<TZMTextField 
												type="number" 
												name="numeroCte"
												autoFocus 
												onChange={this.handleUpdate} 
												label="Nº CT-e" 
												placeholder="Número do CT-e" 
												value={this.state.params.numeroCte} 
											/>
										</div>
										<div className="ui-g-1">
											<TZMTextField 
												type="number" 
												name="numeroFaturaIndicador" 
												autoFocus 
												onChange={this.handleUpdate} 
												label="Nº Fatura" 
												placeholder="Número da Fatura" 
												value={this.state.params.numeroFaturaIndicador} 
											/>
										</div>
										
									</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton className="ui-button-info" label="Ajuda" icon="fa fa-question-circle"  style={{ float: "left" }} onClick={() => this.onClick('ajuda', 'top')}  />
								<Dialog header="Ajuda" visible={this.state.ajuda} position={this.state.position} modal style={{ width: '30vw', textAlign: "left" }} onHide={() => this.onHide('ajuda')}>
									<p>- UF Destino: Limite Frete Peso e Limite Frete Valor</p>
									<p>- Frete Pagar = Peso da NF * (Valor do CT-e / Peso do CT-e)</p>
									<p>- Frete Peso = Frete Pagar / Peso CT-e</p>
									<p>- Frete Valor = Frete Pagar / Valor da NF</p>
								</Dialog>
								<TZMButton className="ui-button-info" style={{ float: "left" }} icon="fas fa-external-link-alt" label="CSV" onClick={this.export} />
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarFreteIndicadoresBase}/>					
							</TZMPanelFooter>
						</TZMPanel>
					</div>
				</div>

				<TZMPanel header="Resultado da Pesquisa">
					<DataTable ref={(el) => this.dt = el}  
					value={this.state.Indicadores} scrollable paginator rows={50}
					csvSeparator=";"
					exportFilename="indicadores"
					footer={(
						<>
							<div style={{textAlign: "right"}}><b>Total de Registros:</b> {toNumber(this.state.Indicadores?.length)}</div>
							{/*<div style={{textAlign: "right"}}><b>Valor Total:</b> {toCurrency(this.state.Indicadores?.map(c => c.notaFiscalValor).reduce((a, b) => a + b, 0))}</div> */}
						</>
					)}>
						
						<Column style={{width: "7em", textAlign: "right"}} header="Fatura" field="numeroFatura" sortable/>
						<Column style={{width: "7em", textAlign: "center"}} header="CT-e" field="cte" sortable/>
						<Column style={{width: "10em", textAlign: "center"}} header="Emissão CT-e" field="dataEmissaoCte" sortable/>
						<Column style={{width: "*"}} header="Transportadora" field="transportadora" sortable/>
						<Column style={{width: "7em", textAlign: "right"}} header="Nota Fiscal" field="notaFiscal" sortable/>
						<Column style={{width: "10em", textAlign: "center"}} header="Emissão NF" field="dataEmissaoNf" sortable/>
						<Column style={{width: "7em", textAlign: "right"}} header="Valor NF" field="notaFiscalValor" sortable/>
						<Column style={{width: "*"}} header="Destinatário" field="empresaDestinataria" sortable/>
						<Column style={{width: "10em"}} header="Cidade Destino" field="cidadeDestinoTrecho" sortable/>
						<Column style={{width: "10em"}} header={"UF Destino"} field="ufDestinoTrecho" sortable/>
						<Column style={{width: "7em", textAlign: "right"}} header="Peso NF" field="notaFiscalPesoBruto" sortable/>
						<Column style={{width: "7em", textAlign: "right"}} header={"Frete Pagar"} field="nfFretePagar" sortable/>

						<Column 
							style={{width: "7em", textAlign: "right"}} sortable
							header={"Frete Peso"}
							body={v => <span style={{color: v.indicadorFretePeso < v.limiteFretePeso ? "#2E7D32" : "#F44336"}}>{v.indicadorFretePeso}</span>}
							field="indicadorFretePeso"
						/>

						<Column
							style={{width: "7em", textAlign: "right"}}  sortable
							header={"Frete Valor"}
							body={v => <span style={{color: v.indicadorFreteValor < v.limiteFreteValor ? "#2E7D32" : "#F44336"}}>{v.indicadorFreteValor}</span>}
							field="indicadorFreteValor"
						/>
					</DataTable>
				</TZMPanel>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
