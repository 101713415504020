import {httpGet, httpGetAsync, httpPost} from "../../../utilities/TZMRequest";

export class CteService {

	completar = async  (cte) => {
		return  httpGetAsync(`/ctes/${cte.id}`);
	}

	 completarCteFromFatura = async(cte) => {
		return  httpGetAsync(`/ctes/${cte.cteId}`);
	}

	listar(query){
		return httpGet(`/ctes?cte=${query}`);
	}

	listarSimples = async (params) => {
        return  httpGetAsync("/ctes/listar-simples", params, true);
    }

	salvar(cte) {
		return httpPost("/ctes", cte);
	}

	lerArquivoXML  = async () => {
        return httpGetAsync("/ctes/ler-arquivos");
    }

	lerArquivoFAT  = async () => {
        return httpGetAsync("/faturas/ler-arquivos");
    }

	processar = async (cte) => {
		return httpGetAsync(`/ctes/processar-cte/${cte.id}`);
	}

	async confirmarAgrupamento(ctes, tipoVeiculo) {
		return await httpPost("/ctes/confirmar-agrupamento", {ctes, tipoVeiculo});
	}

}

export const optionsAgrupamento = [
	{label: "Todos", value: ""},
	{label: "Possível Agrupamento", value: "CANDIDATO"},
	{label: "Agrupamento Confirmado", value: "AGRUPADO"}
];