import {observable} from "mobx";
import {observer} from "mobx-react";
import {Component} from "react";

export const API = observer(class extends Component {

	static empresa = observable.box(process.env.REACT_APP_EMPRESA_NOME);

	static setEmpresa(empresa) {
		API.empresa = empresa;
	}

	render() {
		return null;
	}

});

const companyUrls = {
	"RRE":      process.env.NODE_ENV === "development" ? "http://localhost:8080" : "http://177.47.8.58:8088/frete-rre",
	"COLACRIL": process.env.NODE_ENV === "development" ? "http://localhost:8080" : "http://177.47.8.58:8088/frete-aap",
	/*"HOMOLOGAÇÂO COLACRIL": process.env.NODE_ENV === "development" ? "http://10.103.248.101:8080/frete-aap" : "http://10.103.248.101:8080/frete-aap", */
}

export class Fetch {

	static Get(path) {
		return fetch(`${companyUrls[API.empresa]}${path}`, {method: "get", headers: Fetch.buildHeaders(path)});
	}

	static async GetAsync(path) {
		return await fetch(`${companyUrls[API.empresa]}${path}`, {method: "get", headers: Fetch.buildHeaders(path)});
	}

	static GetJSON(path) {
		return Fetch.Get(path).then((response) => {
			return response.text().then((text) => text ? JSON.parse(text) : {});
		});
	}

	static async GetJSONAsync(path) {
		return await Fetch.GetAsync(path).then((response) => {
			return response.text().then(async (text) => text ? JSON.parse(text) : {});
		});
	}

	static Post(path, body) {
		return fetch(`${companyUrls[API.empresa]}${path}`, {method: "post", body: JSON.stringify(body), headers: Fetch.buildHeaders(path)});
	}

	static async PostAsync(path, body) {
		return await fetch(`${companyUrls[API.empresa]}${path}`, {method: "post", body: JSON.stringify(body), headers: Fetch.buildHeaders(path)});
	}

	static PostJSON(path, body) {
		return Fetch.Post(path, body).then((response) => response.json());
	}

	static async PostJSONAsync(path, body) {
		return await Fetch.PostAsync(path, body).then(async (response) => await response.json());
	}

	static Delete(path) {
		return fetch(`${companyUrls[API.empresa]}${path}`, {method: "delete", headers: Fetch.buildHeaders(path)});
	}

	static async DeleteAsync(path) {
		return await fetch(`${companyUrls[API.empresa]}${path}`, {method: "delete", headers: Fetch.buildHeaders(path)});
	}

	static buildHeaders(path) {
		return {
			"Content-Type": "application/json",
			"Authorization": path.includes("/public") ? "" : window.localStorage.getItem("X-Authorization-Key") || ""
		};
	}

}
