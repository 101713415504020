import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {PanelContent} from "../../../components/common/PanelContent";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMTextField from "../../../components/common/TZMTextField";
import {TZMCurrency} from "../../../components/common/TZMCurrency";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {
	newTabelaFreteElementoCustoFaixa, optionsRateioAgrupamento,
	TabelaFreteElementoCustoArredondamentoOptions,
	TabelaFreteElementoCustoFaixaComponenteOptions,
	TabelaFreteElementoCustoModalidadeOptions,
	TabelaFreteElementoCustoRoundingModeOptions
} from "./MapTabelaFrete";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import {byIdOrKey} from "../../../utilities/FilterUtils";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {TZMDecimal} from "../../../components/common/TZMDecimal";
import {TZMSimpleCheckbox} from "../../../components/common/TZMSimpleCheckbox";
import TZMMultiSelect from "../../../components/common/TZMMutiSelect";

const modalidadesPorIndice = [
	"CALCULADO_INDICE",
	"FIXO_FAIXA_INDICE",
	"CALCULADO_INDICE_INTEGRAL"
];

function notModalidadePorIndice(ec) {
	return !modalidadesPorIndice.includes(ec.modalidade);
}

export class EditarElementoCusto extends Component {

	state = {
		visible: true,
		elementoCusto: this.props.elementoCusto,
		descricoes: []
	};

	componentDidMount() {
		const tabelaFrete = this.props.tabelaFrete;
		if (tabelaFrete) {
			let descricoes = [];
			tabelaFrete.elementosCusto.filter(notModalidadePorIndice).forEach(ec => descricoes.push(ec.descricao));
			tabelaFrete.percursos.forEach(tfp => {
				tfp.elementosCusto.filter(notModalidadePorIndice).forEach(ec => descricoes.push(ec.descricao));
				tfp.destinos.forEach(tfpd => {
					tfpd.incidencias.forEach(tfpdi => {
						tfpdi.elementosCusto.filter(notModalidadePorIndice).forEach(ec => descricoes.push(ec.descricao));
					});
				});
			});
			descricoes = descricoes.filter((d, i, a) => descricoes.indexOf(d) === i);
			this.setState({descricoes: descricoes.map(d => ({label: d, value: d}))});
		}
	}

	onClose = () => this.setState({visible: false});

	salvarElementoCusto = () => {
		const {elementoCusto} = this.state;
		const messages = [];
		if (!elementoCusto.descricao?.length) messages.push("A descrição é obrigatória");
		if (!elementoCusto.modalidade) messages.push("A modalidade de cálculo é obrigatória");
		if (!elementoCusto.faixas?.length && elementoCusto.modalidade !== "FIXO") messages.push("É necessário haver ao menos uma faixa de preço");
		elementoCusto.faixas.forEach(ecf => {
			if (!ecf.faixaLimite) messages.push("Todas as faixas de preço devem ter um limite superior");
		});
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages}/>);
		} else {
			if (this.props.onModalClose) {
				this.props.onModalClose(this.state.elementoCusto);
			}
			this.onClose();
		}
	}

	handleChange = (event) => {
		const {elementoCusto} = this.state;
		elementoCusto[event.name] = event.value;
		this.setState({elementoCusto});
	}

	handleChangeItem = (event) => {
		const {elementoCusto} = this.state;
		elementoCusto.faixas[event.index][event.name] = event.value;
		this.setState({elementoCusto});
	}

	adicionarFaixa = () => {
		const {elementoCusto} = this.state;
		elementoCusto.faixas.push(newTabelaFreteElementoCustoFaixa());
		this.setState({elementoCusto});
	}

	removerFaixa = (faixa) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja remover esta faixa de preço?" onYes={() => {
			const {elementoCusto} = this.state;
			elementoCusto.faixas = elementoCusto.faixas.filter(ecf => byIdOrKey(ecf, faixa));
			this.setState({elementoCusto});
		}}/>);
	}

	handleChangeFlags = (event) => {
		const {elementoCusto} = this.state;
		if (event.value && !elementoCusto.flags.includes(event.name)) {
			elementoCusto.flags.push(event.name);
		} else if (!event.value && elementoCusto.flags.includes(event.name)) {
			elementoCusto.flags = elementoCusto.flags.filter(f => f !== event.name);
		}
		this.setState({elementoCusto});
	}

	handleChangeAcoesLimite = (event) => {
		const {elementoCusto} = this.state;
		if (event.value && !elementoCusto.acoesLimite.includes(event.name)) {
			elementoCusto.acoesLimite.push(event.name);
		} else if (!event.value && elementoCusto.acoesLimite.includes(event.name)) {
			elementoCusto.acoesLimite = elementoCusto.acoesLimite.filter(f => f !== event.name);
		}
		this.setState({elementoCusto});
	}

	modalidadesPorValor = [
		"CALCULADO_VALOR",
		"CALCULADO_VALOR_INTEGRAL",
		"CALCULADO_INDICE",
		"CALCULADO_INDICE_INTEGRAL"
	];

	render() {
		const fixo = this.state.elementoCusto.modalidade === "FIXO";
		return (
			<Dialog style={{width: "600px"}} visible={this.state.visible} modal header="Tabela de Frete - Elemento de Custo" onHide={this.onClose} {...this.props}>
				<PanelContent>
					<TZMTextField col={6} label="Descrição" name="descricao" value={this.state.elementoCusto.descricao} onChange={this.handleChange}/>
					<TZMCombobox col={3} name="modalidade" label="Modalidade" value={this.state.elementoCusto.modalidade} children={TabelaFreteElementoCustoModalidadeOptions} onChange={this.handleChange}/>
					<TZMCombobox col={3} name="faixaComponente" label="Limite das Faixas" value={this.state.elementoCusto.faixaComponente} children={TabelaFreteElementoCustoFaixaComponenteOptions} onChange={this.handleChange}/>
					{this.props.descricoes?.includes(this.state.elementoCusto.descricao) ? <div className="tf-ec-warning">Este elemento de custo possui descrição igual à de outro nível e sobrescreverá o anterior</div> : null}
					{modalidadesPorIndice.includes(this.props.elementoCusto.modalidade) ? (
						<TZMMultiSelect value={this.state.elementoCusto.elementosIndexados} options={this.state.descricoes} name="elementosIndexados" label="Elementos Indexados" col={12} onChange={this.handleChange}/>
					) : null}
					<div style={{position: "relative"}} className="ui-g-12">
						<TZMTable disabled={fixo} value={this.state.elementoCusto.faixas} header="Faixas de Preço" rows={10} paginator paginatorLeft={
							<TZMButton icon="fa fa-plus" onClick={this.adicionarFaixa}/>
						}>
							<TZMColumn header="Valor" body={(ecf, i) => (
								this.modalidadesPorValor.includes(this.state.elementoCusto.modalidade)
									? <TZMDecimal index={i.rowIndex} type="number" col={12} name="valor" value={ecf.valor} onChange={this.handleChangeItem}/>
									: <TZMCurrency index={i.rowIndex} precision={5} col={12} name="valor" value={ecf.valor} onChange={this.handleChangeItem}/>
							)}/>
							<TZMColumn header="Limite Superior (Peso/Valor)" body={(ecf, i) => (
								<TZMTextField max={9999999} index={i.rowIndex} type="number" col={12} name="faixaLimite" value={ecf.faixaLimite} onChange={this.handleChangeItem}/>
							)}/>
							<TZMColumn header="Ações" style={{textAlign: "center", width: "4em"}} body={ecf => (
								<div>
									<i className="ui-tzm-icon-uncoloured fa fa-trash-alt" onClick={() => this.removerFaixa(ecf)}/>
								</div>
							)}/>
						</TZMTable>
						{fixo ? <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, .3)"}}/> : null}
					</div>
					<div className="ui-g-8">
						<div className="ui-g">
							<TZMCombobox disabled={fixo} col={6} name="arredondamento" label="Arredondamento" value={this.state.elementoCusto.arredondamento} children={TabelaFreteElementoCustoArredondamentoOptions} onChange={this.handleChange}/>
							<TZMCurrency disabled={fixo} col={6} label="Valor Limite" name="valorLimite" value={this.state.elementoCusto.valorLimite} onChange={this.handleChange}/>
							<TZMTextField disabled={fixo} type="number" col={6} label="Valor de Arredondamento" name="valorArredondamento" value={this.state.elementoCusto.valorArredondamento} onChange={this.handleChange}/>
							<TZMCurrency col={6} label="Valor Mínimo" name="valorMinimo" value={this.state.elementoCusto.valorMinimo} onChange={this.handleChange}/>
							<TZMCombobox options={optionsRateioAgrupamento} col={6} label="Rateio no Agrupamento" name="rateioAgrupamento" value={this.state.elementoCusto.rateioAgrupamento} onChange={this.handleChange}/>
							<TZMCombobox disabled={fixo} children={TabelaFreteElementoCustoRoundingModeOptions} onChange={this.handleChange} col={3} label="Fração de ¢" name="roundingMode" value={this.state.elementoCusto.roundingMode}/>
							<TZMTextField col={3} label="Desde a entrega" name="desdeEntrega" value={this.state.elementoCusto.desdeEntrega} onChange={this.handleChange}/>
							<NeoSuggestion.TipoVeiculo appendTo={document.body} multiple col={12} label="Tipos de Veículo" name="tiposVeiculo" value={this.state.elementoCusto.tiposVeiculo} onChange={this.handleChange}/>
						</div>
					</div>
					<div className="ui-g-4">
						<div className="ui-g ui-g-nopad">
							<div className="ui-g-12">
								<label className="ui-input-label" style={{marginBottom: "-2px"}}>Quando atingir limite...</label>
							</div>
							<div className="ui-g-12">
								<TZMSimpleCheckbox disabled={fixo} name="LIMITAR" onChange={this.handleChangeAcoesLimite} label="Limitar valor" checked={this.state.elementoCusto.acoesLimite.includes("LIMITAR")}/>
								<TZMSimpleCheckbox disabled={fixo} name="EXCLUSIVO" onChange={this.handleChangeAcoesLimite} label="Cobrar exclusivo" checked={this.state.elementoCusto.acoesLimite.includes("EXCLUSIVO")}/>
							</div>
							<div className="ui-g-12">
								<label className="ui-input-label" style={{marginTop: "4px", marginBottom: "-2px"}}>Opções</label>
							</div>
							<div className="ui-g-12">
								<TZMSimpleCheckbox name="INCIDE_IMPOSTO" onChange={this.handleChangeFlags} label="Incide imposto" checked={this.state.elementoCusto.flags.includes("INCIDE_IMPOSTO")}/>
								<TZMSimpleCheckbox name="EXCLUSIVO" onChange={this.handleChangeFlags} label="Frete fechado" checked={this.state.elementoCusto.flags.includes("EXCLUSIVO")}/>
								<TZMSimpleCheckbox name="RATEIO" onChange={this.handleChangeFlags} label="Rateio" checked={this.state.elementoCusto.flags.includes("RATEIO")}/>
							</div>
						</div>
					</div>
				</PanelContent>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarElementoCusto}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarElementoCusto} onEscape={this.onClose}/>
			</Dialog>
		);
	}

}
