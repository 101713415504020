import moment from "moment";
import React, {Component} from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMRadioButton from '../../../components/common/TZMRadioButton';
import TZMForm from "../../../components/common/TZMForm";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";
import {Column} from "primereact/components/column/Column";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMTable from "../../../components/table/TZMTable";
import {CteService, optionsAgrupamento} from "../servicos/CteService";
import {VisualizarCte} from "./VisualizarCte";
import TZMTextField from "../../../components/common/TZMTextField";
import {MunicipioService} from "../servicos/MunicipioService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {TransportadoraService} from "../servicos/TransportadoraService";
import {ClienteService} from "../../servicos/ClienteService";
import {ClienteUtils} from "../../comum/cliente/ClienteUtils";
import {AuditoriaCteService} from "./AuditoriaCteService";
import {VisualizarAuditoriaCTe} from "../tabela-frete/VisualizarAuditoriaCTe";
import {TransportadoraUtils} from "../transportadora/TransportadoraUtils";
import {MunicipioUtils} from "../municipio/MunicipioUtils";
import {AuditoriaCteStatusOptions} from "../tabela-frete/MapTabelaFrete";
import {Panel} from "primereact/components/panel/Panel";
import {toCurrency, toCurrencyRightAligned, toNumber} from "../../../components/common/TZMFormatter";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {Row} from "primereact/components/row/Row";
import {RevisaoCte} from "./RevisaoCte";
import {httpGet} from "../../../utilities/TZMRequest";
import {saveAs} from "file-saver";
import {cFIB} from "./CabecalhoFreteIndicadores";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import {TZMSimpleCheckbox} from "../../../components/common/TZMSimpleCheckbox";
import {AprovacaoCte} from "./AprovacaoCte";
import {Validator} from '../../../utilities/TZMValidator';
import TZMPopup from '../../../components/dialog/TZMPopup'
import TZMRadioGroup from "../../../components/common/TZMRadioGroup";
import {DataTable} from "primereact/components/datatable/DataTable";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { RelatorioService } from "../../servicos/RelatorioService";
import {ConfimacaoAgrupamento} from "./ConfimacaoAgrupamento";
import {calcularCorLink} from "../../../utilities/StringUtils";

const title = "Fretes";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;
const empresa =  process.env.REACT_APP_EMPRESA;

export class Cte extends React.Component {

	state = {
		ctes: [],
		params: {
			numeroFatura: null,
			numeroFaturaIndicador: null,
			chave: null,
			numeroCte: null,
			numeroPedido: null,
			numeroNf: null,
			cte: null,
			status: null,
			tomadorServico: 'REMETENTE',
			cliente: null,
			municipioOrigem: null,
			municipioDestino:null,
			transportadora:null,
			transportadoraIndicador: null,
			auditoria: null,			
			dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
			dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
			dataEmissao_fim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
			dataEmissao_inicio: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
			filtroRadioGroup: 'NF'
		},
	};

	municipioService = new MunicipioService();
	transportadoraService = new TransportadoraService();
	clienteService = new ClienteService();
	cteService = new CteService();
	auditoriaCteService = new AuditoriaCteService();
	relatorioService = new RelatorioService();	

	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});		
	}

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

	findByNomeOrCodigoOrigem = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosOrigem) => this.setState({municipiosOrigem}));
	}

	findByNomeOrCodigoDestino = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipiosDestino) => this.setState({municipiosDestino}));
	}

	listar = async () => {
		let params = Object.assign({}, this.state.params);
		if (params.cliente) {
			params.cliente = params.cliente.id;
		}
		if (params.transportadora) {
			params.transportadora = params.transportadora.id;
		}
		if (params.municipioDestino) {
			if (params.municipioDestino.codigo) {
				params.municipioDestino = params.municipioDestino.codigo;
			} else {
				params.municipioDestino = null;
			}
		}
		if (params.municipioOrigem) {
			if (params.municipioOrigem.codigo) {
				params.municipioOrigem = params.municipioOrigem.codigo;
			} else {
				params.municipioOrigem = null;
			}
		}
		if (params.status !== "AUDITADO") {
			delete params.auditoria;
		}
		await this.cteService.listarSimples(params).then((ctes) => this.setState({ctes}));
	}

	visualizar = (event) => {
		this.setState({selectedCte: event.data});
		if (event.data != null) {
			this.cteService.completar(event.data).then((cte) => {
				ModalRealm.showDialog(<VisualizarCte key={TZMUserData.getIdGenerator()} cte={cte}  onModalClose={this.listar}  />);
			});
		}
	}

	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	statusAuditado = ["AUDITADO_SUCESSO","AUDITADO_FALHA","AUDITADO_OBSERVACOES","AUDITADO_ERRO","AUDITADO_REVISADO"];

	optionsStatus = [
		<option key={0} value="">Todos</option>,
		<option key={1} value="LIDO">Lido</option>,
		<option key={2} value="PROCESSADO">Processado</option>,
		<option key={3} value="AUDITADO">Auditado</option>,
		<option key={4} value="CANCELADO">Cancelado</option>,
		<option key={5} value="DESCONSIDERADO">Desconsiderado</option>
	];

	optionsTomador = [
		<option key={0} value={null}/>,
		<option key={1} value="REMETENTE">Remetente</option>,
		<option key={2} value="EXPEDIDOR">Expedidor</option>,
		<option key={3} value="RECEBEDOR">Recebedor</option>,
		<option key={4} value="DESTINATARIO">Destinatário</option>,
		<option key={5} value="OUTROS">Outros</option>
	];

	cteStatusIcon = (cte) => {
		let value, color, titulo;
		switch (cte.status) {
			case "LIDO": value = "fa fa-book-open"; color= "#607D8B"; titulo="Lido"; break;			
			case "PROCESSADO": value = "fa fa-road"; color= "#03A9F4"; titulo="Processado"; break;
			case "AUDITADO_SUCESSO": value = "fa fa-check"; color= "#4CAF50"; titulo="Auditado com Sucesso"; break;
			case "AUDITADO_OBSERVACOES": value = "fa fa-info-circle"; color= "#009688"; titulo="Auditado com Observações"; break; 
			case "AUDITADO_FALHA": value = "fa fa-exclamation-triangle"; color= "#FF9800"; titulo="Auditado com Falha"; break;
			case "AUDITADO_ERRO": value = "fa fa-square-xmark"; color= "#F44336"; titulo="Auditado com Erro"; break;
			case "AUDITADO_REVISADO": value = "fa fa-check-circle"; color= "#009688"; titulo="Auditado Revisado"; break;
			case "CANCELADO": value = "fa fa-ban"; color= "#ff0000"; titulo=`Cancelado em ${moment(cte.dataCancelamento).format("DD/MM/YYYY HH:mm:ss")} Motivo: ${cte.motivoCancelamento}` ; break;		
			case "DESCONSIDERADO":  value = "fa fa-trash"; color= "#640404"; titulo="Desconsiderado";break;
			default: break;
		}
		let valueTipo, colorTipo, tituloTipo;
		switch (cte.tipo) {
			case "ENTRADA": valueTipo = "fa fa-warehouse"; colorTipo= "#ff0000"; tituloTipo="CT-e de Entrada"; break;
			case "SAIDA": valueTipo = "fa fa-building"; colorTipo= "#0400ff"; tituloTipo="CT-e de Saída"; break;
			case "DESCONHECIDO": valueTipo = "fa fa-question"; colorTipo= "#607D8B"; tituloTipo="Desconhecido"; break; 
			default: break;
		}
		let valueTipoCte, tituloTipoCte, colorTipoCte;
		switch (cte.tipoCte) {
			case "NORMAL": valueTipoCte = "fa fa-truck-moving"; tituloTipoCte="CT-e Normal"; break;
			case "COMPLEMENTO": valueTipoCte = "fa fa-truck-field ";  tituloTipoCte="CT-e de Complemento de valores"; colorTipoCte="#607D8B";  break;
			case "ANULACAO": valueTipoCte = "fa fa-road-circle-xmark"; tituloTipoCte="CT-e de Anulação"; break;
			case "SUBSTITUICAO": valueTipoCte = "fa fa-truck-fast"; tituloTipoCte="CT-e de Substituição"; break; 
			case "MINUTA": valueTipoCte = "fa fa-truck-pickup";  tituloTipoCte="Minuta"; break;
			case "NAO_IDENTIFICADO": valueTipoCte = "fa fa-circle-question ";  tituloTipoCte="Não Identificado"; break;
			default: break;
		}
		return (
			<div className='ui-g'>
				<div className='ui-g-12' style={{ textAlign: "center" }}>
					<span key={1} className={value} title={titulo} style={{ color, fontSize: "14px" }} />&nbsp;&nbsp;
					<span key={2} className={valueTipo} title={tituloTipo} style={{color: colorTipo, fontSize: "14px" }} />&nbsp;&nbsp;
					<span key={3} className={valueTipoCte} title={tituloTipoCte} style={{color: colorTipoCte, fontSize: "14px" }} />&nbsp;
					{
						!["LIDO", "DESCONSIDERADO", "CANCELADO"].includes(cte.status) && cte.inconsistencias?.length ? (
							<div style={{textAlign: "center"}}>
								<i
									style={{cursor: "pointer", color: "#FF9800"}}
									title="Frete com inconsistências. Clique para mais detalhes."
									onClick={() => {
										ModalRealm.showDialog(<ViewInconsistencias key={Math.random()} inconsistencias={cte.inconsistencias}/>);
									}}
									className="fa fa-exclamation-triangle blink-orange-red"
								/>
							</div>
						) : null
					}
				</div>
			</div>
		);
	}

	toggleSelecionado = (cte) => {
		const {ctes} = this.state;
		ctes.filter(c => c.id === cte.id).forEach(c => c._selecionado = !c._selecionado);
		this.setState({ctes});
	}

	toggleAllSelecionados = () => {
		const {ctes} = this.state;
		const _selecionado = ctes.every(c => c._selecionado);
		ctes.forEach(c => c._selecionado = !_selecionado);
		this.setState({ctes});
	}

	printDiferenca = (cte) => {
		switch (cte.status) {
		case "AUDITADO_ERRO":
			return <div style={{textAlign: "right"}}>?</div>;
		case "AUDITADO_FALHA":
			return toCurrencyRightAligned(cte.valorTotal - cte.valorAuditoria);
		default:
			return <div style={{textAlign: "right"}}>-</div>;
		}
	}

	selecionar = (cte) => {
		if (cte.status !== 'LIDO' &&  cte.status !== 'DESCONSIDERADO' && cte.status !== 'CANCELADO' && cte.status !== 'AUDITADO_SUCESSO') {
			return <TZMSimpleCheckbox checked={cte._selecionado} onChange={() => this.toggleSelecionado(cte)}/>;
		}
		return null;
	}

	columns = [
		<Column key="_selector" body={cte => (this.selecionar(cte))} style={{textAlign: "center"}}/>,
		<Column key="numeroFatura" field="numeroFatura" style={{textAlign: "right"}} />,
		<Column key="numero" field="numero" style={{textAlign: "right"}} body={cte => (
			<div>
				{
					cte.cobrancasAgrupadas > 1 ? (
						<>
							<i
								title={cte.cobrancaRoteirizada === "AGRUPADO" ? "Entrega Agrupada" : "Possível Entrega Agrupada"}
								className="fa fa-chain"
								style={{
									color: calcularCorLink(cte.codigoCobrancaAdicional),
									cursor: cte.cobrancaRoteirizada === "AGRUPADO" ? "default" : "pointer"
								}}
								onClick={() => {
									if (cte.cobrancaRoteirizada === "AGRUPADO") {
										return;
									}
									const ctes = this.state.ctes.filter(c => c.codigoCobrancaAdicional === cte.codigoCobrancaAdicional);
									ModalRealm.showDialog(<ConfimacaoAgrupamento ctes={ctes} onModalClose={this.listar}/>);
								}}
							/>
						</>
					) : null
				}
				{` ${cte.numero}`}
			</div>
		)}/>,
		<Column key="dataEmissao" field="dataEmissao" body={(rowData) => (
			<div style={{textAlign: "center"}}>
				{moment(rowData.dataEmissao).format("DD/MM/YYYY")}<br/>
				{moment(rowData.dataEmissao).format("HH:mm:ss")}
			</div>
		)}/>,
		<Column key="destinatario" body={cte => (
			<div>
				<div style={{padding: "0 .5em"}}><i className="fa fa-user-tie green"/> {cte.remetente}</div>
				<div style={{padding: "0 .5em", borderTop: "1px solid rgba(50, 50, 50, .1)"}}><i className="fa fa-user red"/> {cte.destinatario}</div>
			</div>
		)}/>,
		<Column key="transporte" body={cte => (
			<div>
				<div style={{padding: "0 .5em"}}><i className="fa fa-truck lead"/> {cte.transportadora}</div>
				<div style={{display: "flex", borderTop: "1px solid rgba(50, 50, 50, .1)"}}>
					<div style={{padding: "0 .5em", flex: 1}}><i style={{transform: "rotate(-45deg)"}} className="fa fa-arrow-right green"/> {cte.origem}</div>
					<div style={{padding: "0 .5em", flex: 1, borderLeft: "1px solid rgba(50, 50, 50, .1)"}}><i style={{transform: "rotate(45deg)"}} className="fa fa-arrow-right red"/> {cte.destino}</div>
				</div>
			</div>
		)}/>,
		<Column key="status" field="status" body={this.cteStatusIcon}/>,
		<Column key="valorReal" body={cte => <span style={{color: cte.valorTotal === cte.valorCorreto ? "#2E7D32" : "#F44336"}}>{toCurrencyRightAligned(cte.valorTotal)}</span>}/>,
		<Column key="valorAuditoria" body={cte => this.statusAuditado.includes(cte.status) ? <span style={{color: cte.valorAuditoria === cte.valorCorreto ? "#2E7D32" : "#F44336"}}>{toCurrencyRightAligned(cte.valorAuditoria)}</span> : <div style={{textAlign: "right"}}>-</div>}/>,
		<Column key="diferenca" body={this.printDiferenca}/>,
		<Column key="valorCorreto" body={cte => cte.valorCorreto ? toCurrencyRightAligned(cte.valorCorreto) : <div style={{textAlign: "right"}}>?</div>}/>,
		<Column key="acoes" body={(cte) => {
			return (
				<div style={{textAlign: "center"}}>
					<span title="Visualizar Frete" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizar({data: cte})}/>
					{cte.status === "AUDITADO_FALHA" || cte.status === "AUDITADO_ERRO" ? <span title="Revisar Auditoria de Frete" className="fa fa-file-contract ui-tzm-icon" onClick={() => this.revisarCte(cte)}/> : null}
				</div>
			);
		}}/>
	];

	visualizarAuditoria = (cte) => {
		this.auditoriaCteService.buscarPorCte(cte).then(auditorias => {
			ModalRealm.showDialog(<VisualizarAuditoriaCTe cte={cte} auditoria={auditorias[0]}/>);
		});
	}

	autoCompleteTransportadora = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({ transportadoras: response });
		});
	}

	autoCompleteTransportadoraIndicador = (event) => {
		this.transportadoraService.autoComplete({transportadora: event.query, sort: "razaoSocial"}).then((response) => {
			this.setState({ transportadorasIndicador: response });
		});
	}

	autoCompleteCliente = async (event) => {
		await this.clienteService.autoComplete({cliente: event.query, desativados: false, sort: 'razao'}).then((clientes) => this.setState({clientes}));
	}

	revisarCte = (cte) => {
		this.cteService.completar(cte).then(cte => {
			ModalRealm.showDialog(<RevisaoCte key={Math.random()} cte={cte} onModalClose={this.listar}/>);
		});
	}

	ptBR_Format = Intl.NumberFormat("pt-BR");
	ptBR_CurrencyFormat = Intl.NumberFormat("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});

	listarFreteIndicadoresBase = () => {
		
		let messages = [];

		const validacaoDatafim = Validator.isDate(this.state.params?.dataEmissao_fim);
		const validacaoDataIncio = Validator.isDate(this.state.params?.dataEmissao_inicio);
		const periodoInvalido = this.state.params?.dataEmissao_fim < this.state.params?.dataEmissao_inicio;
		if(!validacaoDataIncio && !validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador){
			messages.push('Você deve informar pelo menos um dos campos (Período, Transportadora ou Nº da Fatura)')
		}else{
			if(!validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data final inválida ou não informada.') 
			if(!validacaoDataIncio && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data inicial inválida ou não informada.') 
			if(periodoInvalido && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Período informado inválido, a data final deve ser maior ou igual que a inicial.' )
		}
		

		if(messages !== null && messages.length > 0){
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages}></TZMPopup>);
		}else{
			
			let params = [];
			if (this.state.params?.filtroRadioGroup) params.push(`filtroRadioGroup=${this.state.params?.filtroRadioGroup}`);
			if (this.state.params?.dataEmissao_inicio) params.push(`dataEmissao_inicio=${this.state.params?.dataEmissao_inicio}`);
			if (this.state.params?.dataEmissao_fim) params.push(`dataEmissao_fim=${this.state.params?.dataEmissao_fim}`);
			if (this.state.params?.transportadoraIndicador) params.push(`transportadora=${this.state.params?.transportadoraIndicador.id}`);
			if (this.state.params?.numeroFaturaIndicador) params.push(`numeroFatura=${this.state.params?.numeroFaturaIndicador}`);
			
			httpGet(`/vw/frete-indicadores-base?${params.join("&")}`).then(fibs => {
				if (fibs && fibs.length) {
					let file = [
						Object.keys(fibs[0])
							.filter(k => !cFIB[k].hidden)
							.map(k => cFIB[k].header)
							.join(";")
					];
					file = file.concat(fibs.map(fib =>
						Object.keys(fib)
							.filter(k => !cFIB[k].hidden)
							.map(k => {
								let prop = String(fib[k] || "").replace(/;/g, ",");
								switch (cFIB[k].format) {
								case "currency":
									return isNaN(prop) ? prop : `R$ ${this.ptBR_CurrencyFormat.format(prop)}`;
								case "date":
									return prop ? moment(prop).format("DD/MM/YYYY") : null
								default:
									return isNaN(prop) ? prop : this.ptBR_Format.format(prop);
								}
							}).join(";")
					));
					file = file.join("\r\n");
					const csvBlob = new Blob([file], {type: "application/csv;charset=utf-8"});
					saveAs(csvBlob, "Indicadores-Frete.csv");
				} else {
					ModalRealm.showDialog(<TZMPopup header="Mensagem do servidor" messages={['Nenhum indicador encontrado com os parâmetros informados.']}></TZMPopup>);
				}
			})
		}
	}

	csv = async () => {

		let messages = [];

		const validacaoDatafim = Validator.isDate(this.state.params?.dataEmissao_fim);
		const validacaoDataIncio = Validator.isDate(this.state.params?.dataEmissao_inicio);
		const periodoInvalido = this.state.params?.dataEmissao_fim < this.state.params?.dataEmissao_inicio;
		if(!validacaoDataIncio && !validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador){
			messages.push('Você deve informar pelo menos um dos campos (Período, Transportadora ou Nº da Fatura)')
		}else{
			if(!validacaoDatafim && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data final inválida ou não informada.') 
			if(!validacaoDataIncio && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Data inicial inválida ou não informada.') 
			if(periodoInvalido && !this.state.params?.transportadoraIndicador && !this.state.params?.numeroFaturaIndicador) messages.push('Período informado inválido, a data final deve ser maior ou igual que a inicial.' )
		}
		

		if(messages !== null && messages.length > 0){
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages}></TZMPopup>);
		}else{
			let params = [];
			if (this.state.params?.filtroRadioGroup) params.push(`filtroRadioGroup=${this.state.params?.filtroRadioGroup}`);
			if (this.state.params?.dataEmissao_inicio) params.push(`dataEmissao_inicio=${this.state.params?.dataEmissao_inicio}`);
			if (this.state.params?.dataEmissao_fim) params.push(`dataEmissao_fim=${this.state.params?.dataEmissao_fim}`);
			if (this.state.params?.transportadoraIndicador) params.push(`transportadora=${this.state.params?.transportadoraIndicador.id}`);
			if (this.state.params?.numeroFaturaIndicador) params.push(`numeroFatura=${this.state.params?.numeroFaturaIndicador}`);
			
			await this.relatorioCSV(params).then(response => {});
		}
	}

	relatorioCSV = async (params) => {
		return this.relatorioService.postReport(`/indicadores/frete-base?${params.join("&")}`, 'Indicadores', '.csv');        
    }

	lerArquivoXML = async () => {
		await this.cteService.lerArquivoXML();
	}

	lerArquivoFAT = async () => {
		await this.cteService.lerArquivoFAT();
	}

	statusNaoReauditaveis = [
		"LIDO",
		"DESCONSIDERADO",
		"CANCELADO",
		"AUDITADO_SUCESSO"
	];

	auditarSelecionados = async () => {
		const {ctes} = this.state;
		for (const cte of ctes.filter(c => c._selecionado && !this.statusNaoReauditaveis.includes(c.status))) {
			await this.auditoriaCteService.auditar(await this.cteService.completar(cte));
		}
		await this.listar();
	}

	aprovarSelecionados = async () => {
		ModalRealm.showDialog(<AprovacaoCte ctes={this.state.ctes.filter(cte => cte._selecionado)} onModalClose={this.listar}/>);
	}

	agruparSelecionados = async () => {
		ModalRealm.showDialog(<ConfimacaoAgrupamento ctes={this.state.ctes.filter(cte => cte._selecionado)} onModalClose={this.listar}/>);
	}

	handleChangeRadioButton = (event) => {
		let params = this.state.params;
		params.filtroRadioGroup = event.value;
		this.setState({params});
	}

	processarCtes = () => {
		httpGet("/ctes/processar").then(this.listar);
	}

	processarIntegracao = () => {
		httpGet("/ctes/processar-integracao").then(this.listar);
	}

	preencherValor = () => {
		httpGet("/ctes/preencher-valor").then(this.listar);
	}

	auditarCtes = () => {
		httpGet("/auditorias-cte/auditar").then(this.listar);
	}

	preencherTipoCte = () => {
		httpGet("/ctes/preencher-tipo").then(this.listar);
	}

	criarNfTZM = () => {
		httpGet("/ctes/ler-notas-fiscais").then(this.listar);
	}

	render() {		
		return (
			<TZMMainContent>				
				<div className="ui-g">
					<div className="ui-g-11">
						<TZMPanel header={title}>
							<TZMTabView>
								<TZMTabPanel header="Fretes">
									<TZMForm>
										<div className="ui-g">
											<div className="ui-g-12">
												<div className="ui-g-1">
													<TZMTextField type="number" name="numeroFatura" autoFocus onChange={this.handleUpdate} label="Nº Fatura" placeholder="Número da Fatura" value={this.state.params.numeroFatura} />
												</div>
												<div className="ui-g-1">
													<TZMCalendar label="Período de Emissão" placeholder="Inicial" name="dataIni" onChange={this.handleUpdate} value={this.state.params.dataIni} />
												</div>
												<div className="ui-g-1">
													<TZMCalendar label="&nbsp;" name="dataFim" placeholder="Final" onChange={this.handleUpdate} value={this.state.params.dataFim} />
												</div>
												<div className="ui-g-2">
													<TZMTextField name="chave" autoFocus onChange={this.handleUpdate} label="Chave" placeholder="Chave CT-e" value={this.state.params.chave} />
												</div>
												<div className="ui-g-1">
													<TZMTextField type="number" name="numeroCte"  placeholder="Número CT-e" label="CT-e" onChange={this.handleUpdate} value={this.state.params.numeroCte} />
												</div>
												<div className="ui-g-1">
													<TZMTextField type="number" name="numeroPedido"  placeholder="Número Pedido" label="Pedido" onChange={this.handleUpdate} value={this.state.params.numeroPedido} />
												</div>
												<div className="ui-g-1">
													<TZMTextField type="number" name="numeroNf"  placeholder="Número NF" label="Nota Fiscal" onChange={this.handleUpdate} value={this.state.params.numeroNf} />
												</div>
												<div className="ui-g-1">
													<TZMCombobox value={this.state.params.status} name="status" label="Status" onChange={this.queryAutoComplete} >
														{this.optionsStatus}
													</TZMCombobox>
												</div>
												<TZMCombobox children={AuditoriaCteStatusOptions} disabled={this.state.params.status !== "AUDITADO"} col={1} label="Status da Auditoria" name="auditoria" value={this.state.params.auditoria} onChange={this.handleUpdate}/>
												<TZMCombobox options={optionsAgrupamento} col={2} label="Agrupamento" name="agrupamento" value={this.state.params.agrupamento} onChange={this.handleUpdate}/>
												<div className="ui-g-2">
													<TZMAutoComplete onClear={this.handleClear} itemTemplate={MunicipioUtils.municipioTemplate} onSelect={this.queryAutoComplete}
																	 suggestions={this.state.municipiosOrigem} completeMethod={this.findByNomeOrCodigoOrigem} name="municipioOrigem" field="nome"
																	 placeholder="Nome, Código IBGE" onChange={this.queryAutoComplete} value={this.state.params.municipioOrigem} label="Munícipio Origem" />
												</div>
												<div className="ui-g-2">
													<TZMAutoComplete onClear={this.handleClear} itemTemplate={MunicipioUtils.municipioTemplate} onSelect={this.queryAutoComplete}
																	 suggestions={this.state.municipiosDestino} completeMethod={this.findByNomeOrCodigoDestino} name="municipioDestino" field="nome"
																	 placeholder="Nome, Código IBGE" onChange={this.queryAutoComplete} value={this.state.params.municipioDestino} label="Munícipio Destino" />
												</div>
												<div className="ui-g-3">
													<TZMAutoComplete itemTemplate={TransportadoraUtils.transportadoraTemplate} onClear={this.handleClear}  onSelect={this.queryAutoComplete} onChange={this.queryAutoComplete}
																	 placeholder="Razão social, fantasia ou CNPJ" suggestions={this.state.transportadoras} completeMethod={this.autoCompleteTransportadora} name="transportadora"
																	 label="Transportadora" value={this.state.params.transportadora} field="razaoSocial" />
												</div>
												<div className="ui-g-3">
													<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.queryAutoComplete} onChange={this.queryAutoComplete}
																	  suggestions={this.state.clientes} completeMethod={this.autoCompleteCliente} name="cliente" field="descricaoAmigavel"
																	  label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.params.cliente} />
												</div>
												<div className="ui-g-2">
													<TZMCombobox value={this.state.params.tomadorServico} name="tomadorServico" label="Tomador do Serviço" onChange={this.queryAutoComplete} >
														{this.optionsTomador}
													</TZMCombobox>
												</div>
											</div>
										</div>
										<TZMPanelFooter>
											<div style={{float: "left"}}>
												<TZMButton label="Ler XMLs" onClick={this.lerArquivoXML} tooltip="Realiza a leitura dos arquivos na pasta."/>
												<TZMButton label="Ler Faturas" onClick={this.lerArquivoFAT} tooltip="Realiza a leitura dos arquivos na pasta."/>
												<TZMButton label="Processar CT-es" onClick={this.processarCtes}/>
												<TZMButton label="Auditar CT-es" onClick={this.auditarCtes}/>
											</div>
											{TZMUserData.getUsuario().login === "admin" ? <TZMButton label="Criar NF TZM" onClick={this.criarNfTZM}/> : null}
											{TZMUserData.getUsuario().login === "admin" ? <TZMButton label="Preencher Tipo" onClick={this.preencherTipoCte}/> : null}
											{TZMUserData.getUsuario().login === "admin" ? <TZMButton label="Processar Integração" onClick={this.processarIntegracao}/> : null}
											{TZMUserData.getUsuario().login === "admin" ? <TZMButton label="Preencher Valor Total Produtos" onClick={this.preencherValor}/> : null}
											<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar}/>


										</TZMPanelFooter>
									</TZMForm>
								</TZMTabPanel>
								<TZMTabPanel header="Indicadores de Frete">
									<div className="ui-g">
										<TZMCalendar
											col={2}
											label="Período"
											placeholder="Inicial"
											name="dataEmissao_inicio"
											onChange={this.handleUpdate}
											value={this.state.params.dataEmissao_inicio}
										/>
										<TZMCalendar
											col={2}
											label="&nbsp;"
											placeholder="Final"
											name="dataEmissao_fim"
											onChange={this.handleUpdate}
											value={this.state.params.dataEmissao_fim}/>
										<TZMRadioGroup label="Referência (Período)" col={3}>
											<TZMRadioButton
												name="radioButton"
												label="Data de Emissão da NF"
												value="NF"
												onChange={e => this.handleChangeRadioButton(e)}
												checked={this.state.params.filtroRadioGroup === "NF"}
												id="rb1"/>
											<TZMRadioButton
												name="radioButton"
												label="Data de Emissão do CT-e"
												value="CTE"
												onChange={e => this.handleChangeRadioButton(e)}
												checked={this.state.params.filtroRadioGroup === "CTE"}
												id="rb2"/>
										</TZMRadioGroup>

										<div className="ui-g-4">
											<TZMAutoComplete 
												itemTemplate={TransportadoraUtils.transportadoraTemplate} 
												onClear={this.handleClear}  
												onSelect={this.queryAutoComplete} 
												onChange={this.queryAutoComplete}																
												placeholder="Razão social, fantasia ou CNPJ" 
												suggestions={this.state.transportadorasIndicador} 
												completeMethod={this.autoCompleteTransportadoraIndicador} 
												name="transportadoraIndicador"
												label="Transportadora" 
												value={this.state.params.transportadoraIndicador} 
												field="razaoSocial" 
											/>
										</div>
										<div className="ui-g-1">
											<TZMTextField 
												type="number" 
												name="numeroFaturaIndicador" 
												autoFocus 
												onChange={this.handleUpdate} 
												label="Nº Fatura" 
												placeholder="Número da Fatura" 
												value={this.state.params.numeroFaturaIndicador} 
											/>
										</div>
										
									</div>
									<TZMPanelFooter>
										<TZMButton icon="fas fa-file-csv" label="Imprimir CSV" onClick={this.listarFreteIndicadoresBase}/>
										{
											empresa ===  "COLACRIL" ? 
												<TZMButton icon="fas fa-file-csv" label="Imprimir CSV (Em Validação)" onClick={this.csv}/>
											: 	
												null
										}
									</TZMPanelFooter>
								</TZMTabPanel>
							</TZMTabView>
						</TZMPanel>
					</div>
					<div className="ui-g-1">
						<Panel header="Legenda">
							<div className="ui-g">
								<div style={{ color: '#607D8B', textAlign: "center" }} title="Lido"  className="ui-g-2"><i className="fa fa-book-open"/></div><div className="ui-g-10">Lido</div>
								<div style={{ color: '#03A9F4', textAlign: "center" }} title="Processado" className="ui-g-2"><i className="fa fa-road" /></div><div className="ui-g-10">Processado</div>
								<div style={{ color: '#4CAF50', textAlign: "center" }} title="Auditado com sucesso" className="ui-g-2"><i className="fa fa-check" /></div><div  className="ui-g-10">Sucesso</div>
								<div style={{ color: '#009688', textAlign: "center" }} title="Auditado com observações" className="ui-g-2"><i className="fa fa-info-circle" /></div><div  className="ui-g-10">Observação</div>
								<div style={{ color: '#FF9800', textAlign: "center" }} title="Divergência na auditoria" className="ui-g-2"><i className="fa fa-exclamation-triangle" /></div><div  className="ui-g-10">Divergência</div>
								<div style={{ color: '#F44336', textAlign: "center" }} title="Erro no processo de auditoria" className="ui-g-2"><i className="fa fa-square-xmark" /></div><div  className="ui-g-10">Erro</div>
								<div style={{ color: '#009688', textAlign: "center" }} title="Auditoria revisada" className="ui-g-2"><i className="fa fa-check-circle" /></div><div  className="ui-g-10">Revisado</div>
								<div style={{ color: '#ff0000', textAlign: "center" }} title="Cancelado" className="ui-g-2"><i className="fa fa-ban" /></div><div  className="ui-g-10">Cancelado</div>
								<div style={{ color: '#640404', textAlign: "center" }} title="Desconsiderado" className="ui-g-2"><i className="fa fa-trash" /></div><div  className="ui-g-10">Desconsiderado</div>
							</div>
						</Panel>
					</div>
					<TZMPanel header={<span>Resultado da Pesquisa</span>}>
						<TZMTable
							header={(
								<>
								  <div style={{textAlign: "right"}}><b>Total de Registros:</b> {toNumber(this.state.ctes?.length)}</div>
								  <div style={{textAlign: "right"}}><b>Valor Total:</b> {toCurrency(this.state.ctes?.map(c => c.valorTotal).reduce((a, b) => a + b, 0))}</div>
								</>
							)}
							headerColumnGroup={(
								<ColumnGroup>
									<Row>
										<Column header={(
											<TZMSimpleCheckbox checked={this.state.ctes.every(c => c._selecionado)} onChange={this.toggleAllSelecionados}/>
										)} rowSpan={2} style={{textAlign: "center", width: "3em"}}/>
										<Column sortable field="numeroFatura" header="Nº Fatura" rowSpan={2} style={{width: "9em"}}/>
										<Column sortable field="numero" header="Nº CT-e" rowSpan={2} style={{width: "7em"}}/>
										<Column sortable field="dataEmissao" header="Data Emissão" rowSpan={2} style={{width: "9em"}}/>
										<Column header="Remetente / Destinatário" rowSpan={2} style={{width: "*"}}/>
										<Column header={(
											<div>
												<div>Transportadora</div>
												<div>Origem / Destino</div>
											</div>
										)} rowSpan={2} style={{width: "*"}}/>
										<Column sortable field="status" header="Status" rowSpan={2} style={{textAlign: "center",width: "7em"}}/>
										<Column header="Valor" colSpan={4} style={{width: "28em"}}/>
										<Column header="Ações" rowSpan={2} style={{width: "4.5em", textAlign: "center"}}/>
									</Row>
									<Row>
										<Column header="Real"/>
										<Column header="Auditoria"/>
										<Column header="Diferença"/>
										<Column header="Correto"/>
									</Row>
								</ColumnGroup>
							)}
							value={this.state.ctes}
							paginator
							paginatorLeft={(
								<div>
									<TZMButton disabled={!this.state.ctes.some(c => c._selecionado)}
											 label="Auditar Selecionados"
											 icon="fas fa-sync"
											 onClick={this.auditarSelecionados}/>
									<TZMButton disabled={!this.state.ctes.some(c => c._selecionado)}
											 label="Aprovar Selecionados"
											 icon="fas fa-check"
											 onClick={this.aprovarSelecionados}/>
									<TZMButton disabled={!this.state.ctes.some(c => c._selecionado)}
											 label="Agrupar Selecionados"
											 icon="fas fa-boxes"
											 onClick={this.agruparSelecionados}/>
								</div>
							)}
							rows={50}
							rowsPerPageOptions={[50, 100, 200]}
							rowClassName={cte => {
								if (cte.cobrancaRoteirizada === "AGRUPADO" || !cte.codigoCobrancaAdicional?.length) {
									return {};
								}
								return {[`bg-${calcularCorLink(cte.codigoCobrancaAdicional)[1]}`]: true};
							}}
							footer={(
								<>
								  <div style={{textAlign: "right"}}><b>Total de Registros:</b> {toNumber(this.state.ctes?.length)}</div>
								  <div style={{textAlign: "right"}}><b>Valor Total:</b> {toCurrency(this.state.ctes?.map(c => c.valorTotal).reduce((a, b) => a + b, 0))}</div>
								</>
							)}
							children={this.columns}
						/>
					</TZMPanel>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm/>
			</TZMMainContent>
		);
	}

}

class ViewInconsistencias extends Component {

	state = {
		visible: true
	};

	onClose = () => this.setState({visible: false});

	render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Inconsistências do Frete" onHide={this.onClose} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<DataTable value={this.props.inconsistencias}>
							<Column header="Mensagem" body={i => MapCteInconsistencias[i]}/>
						</DataTable>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}

export const MapCteInconsistencias = {
	"PESO_BRUTO_ZERADO": "Uma ou mais notas fiscais está(ão) com o peso bruto zerado",
	"VALOR_NOTA_FISCAL_ZERADO": "Uma ou mais notas fiscais está(ão) com o valor zerado",
	"VALOR_TOTAL_PRODUTOS_ZERADO": "Uma ou mais notas fiscais está(ão) com o valor total dos produtos zerado"
};