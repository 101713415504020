import {httpGet, httpPost} from "../../../utilities/TZMRequest";

export class UfService {

	async listar() {
		return await httpGet(`/ufs?size=100`);
	}

	listarTela(query) {
		return httpGet(`/ufs${query}`);
	}

	salvar(uf) {
		return httpPost("/ufs", uf);
	}
	
}
