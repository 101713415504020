import React, {Component} from "react";
import TZMForm from "../../../components/common/TZMForm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMUserData from "../../../utilities/TZMUserData";
import { UfService } from "../servicos/UfService";

export class EditarUf extends Component {

	state = {
		visible: true,
		uf: this.props.uf
	};

	ufServico = new UfService();

	onClose = () => this.setState({visible: false});

	salvarUf = () => {
		this.ufServico.salvar(this.state.uf).then(uf => {
			if (this.props.onModalClose) {
				this.props.onModalClose();
			}
			this.onClose();
		})
	}

	handleChange = (event) => {
		const {uf} = this.state;
		uf[event.name] = event.value;
		this.setState({uf});
	}

	render() {
		return (
			<TZMDialog style={{width: "750px"}} visible={this.state.visible} modal header="Uf" onHide={this.onClose} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<div className="ui-g">
							<TZMTextField label="Codigo" col={1} name="codigo" value={this.state.uf.codigo}/>
							<TZMTextField label="Nome" col={5} name="nome" value={this.state.uf.nome}/>
							<TZMTextField label="Sigla" col={2} name="sigla" value={this.state.uf.sigla}/>
							<TZMTextField label="limiteFretePeso" col={2} name="limiteFretePeso" onChange={this.handleChange} value={this.state.uf.limiteFretePeso}/>
							<TZMTextField label="limiteFreteValor" col={2} name="limiteFreteValor" onChange={this.handleChange} value={this.state.uf.limiteFreteValor}/>
							
						</div>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					{TZMUserData.hasRole("FRETE_MOTO_E") ? <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarUf}/> : null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}
