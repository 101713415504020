export function byDescricao(a, b) {
	return a.descricao.localeCompare(b.descricao);
}

export function byTransportadora(a, b) {
	return a.transportadora.razaoSocial.localeCompare(b.transportadora.razaoSocial);
}

export function byNome(a, b) {
	return a.nome.localeCompare(b.nome);
}

export function byUfOrMunicipio(a, b) {
	if (a.uf) {
		if (b.uf) {
			return a.uf.nome.localeCompare(b.uf.nome);
		}
		return -1;
	}
	if (b.uf) {
		return 1;
	}
	return a.municipio?.nome.localeCompare(b.municipio?.nome);
}

export function byFaixaLimite(a, b) {
	return a.faixaLimite - b.faixaLimite;
}

export function byNumero(a, b) {
	return a.numero - b.numero;
}
