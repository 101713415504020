import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import {CteService} from "../servicos/CteService";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {byNumero} from "../../../utilities/SortUtils";
import TZMTextField from "../../../components/common/TZMTextField";
import {toCurrency} from "../../../components/common/TZMFormatter";
import {NeoSuggestion} from "../../../components/common/NeoSuggestion";

export class ConfimacaoAgrupamento extends React.Component {

	state = {
		ctes: [],
		_key: Math.random(),
		visible: true,
		remetente: "",
		origem: ""
	};

	componentDidMount() {
		this.setState({
			remetente: this.props.ctes[0].remetente,
			origem: this.props.ctes[0].origem,
			ctes: this.props.ctes.sort(byNumero)
		});
	}

	cteService = new CteService();

	handleClose = () => this.setState({visible: false});

	handleAgrupamento = async () => {
		await this.cteService.confirmarAgrupamento(this.props.ctes.map(cte => cte.id), this.state.tipoVeiculo);
		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
		this.handleClose();
	}

	handleChange = e => this.setState({[e.name]: e.value});

	render() {
		return (
			<TZMDialog
				key={this.state._key}
				style={{width: "700px"}}
				visible={this.state.visible}
				modal
				header="Agrupamento de Fretes"
				onHide={this.onClose}
				{...this.props}
			>
				<div className="ui-g">
					<TZMTextField label="Remetente" col={5} value={this.state.remetente}/>
					<TZMTextField label="Origem" col={4} value={this.state.origem}/>
					<NeoSuggestion.TipoVeiculo col={3} name="tipoVeiculo" label="Tipo de Veículo" value={this.state.tipoVeiculo} onChange={this.handleChange}/>
					<div className="ui-g-12">
						<DataTable value={this.state.ctes} footer={(
							<div style={{textAlign: "right"}}>
								{toCurrency(this.state.ctes.map(cte => cte.valorTotal).reduce((a, b) => a + b, 0))}
							</div>
						)}>
							<Column style={{width: "8em", textAlign: "right"}} header="Número" field="numero"/>
							<Column
								header="Destinatário"
								field="destinatario"
								body={cte => (
									<span style={{padding: "0 .5em", whiteSpace: "nowrap"}}>
										<i className="fa fa-user red"/> {cte.destinatario}
									</span>
								)}
							/>
							<Column
								header="Destino"
								field="destino"
								body={cte => (
									<span style={{padding: "0 .5em", whiteSpace: "nowrap"}}>
										<i style={{transform: "rotate(45deg)"}} className="fa fa-arrow-right red"/>
										{` ${cte.destino}`}
									</span>
								)}
							/>
							<Column
								style={{width: "8em", textAlign: "right"}}
								header="Valor Total" field="valorTotal"
								body={cte => toCurrency(cte.valorTotal)}
							/>
						</DataTable>
					</div>
				</div>
				<TZMPanelFooter>
					<TZMButton label="Confirmar" icon="fas fa-check-circle" onClick={this.handleAgrupamento}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.handleClose}/>
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}
